const React = require('react');
const { string, shape } = require('prop-types');
const Script = require('nordic/script');
const classNames = require('classnames');

const DESKTOP_PLATFORM = 'desktop';

const UI_VPP_DESKTOP = 'ui-billboard-ad';
const UI_VPP_MOBILE = 'ui-fullscreen-ad-pdp loaded';

const AdvertisingAdn = props => {
  const { content, title } = props;
  const { queries } = content || {};
  const { platform, slot_id, ad_unit, placement } = queries || {};
  const style = slot_id ? slot_id.includes('motors') || slot_id.includes('state') : null;
  const layout = ad_unit ? ad_unit.includes('PDP') || ad_unit.includes('UPP') : null;

  const namespace = platform === DESKTOP_PLATFORM ? UI_VPP_DESKTOP : UI_VPP_MOBILE;

  const isContextAd = placement === 'context';

  const [adLoaded, setAdLoaded] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const loaded = () => setAdLoaded(true);
    const node = ref.current;

    node.addEventListener('adn-banner-loaded', loaded);

    return () => {
      node.removeEventListener('adn-banner-loaded', loaded);
    };
  }, []);

  return (
    <div
      id={namespace}
      className={
        isContextAd
          ? classNames('container-advertising', `${namespace} billboard-context`, { placement: layout })
          : classNames('container-advertising', namespace, { placement: style })
      }
    >
      {adLoaded && isContextAd && (
        <div className={`${namespace}__title-container`}>
          {title && <span className={`${namespace}__title`}>{title}</span>}
          {title && <div className={`${namespace}__line`} />}
        </div>
      )}
      <Script src="https://http2.mlstatic.com/frontend-assets/adn-frontend-library/adn-script.js" />
      <div data-component="adn" data-content={JSON.stringify(content)} ref={ref} />
    </div>
  );
};

AdvertisingAdn.propTypes = {
  title: string,
  content: shape({
    category: string,
    page: string,
    placement: string,
    platform: string,
    site_id: string,
    slot_id: string,
    title: string,
  }).isRequired,
};

module.exports = AdvertisingAdn;
